/* IMPORTANT: CHANGE THIS FILE ONLY IN FLOATWORK_ TEMPLATE */

$purple: 						#6E5C67;
$purple-bright:			#82849E;
$purple-light: 			#E7E6EF;
$blue-bright: 			#009FFF;
$blue-dark: 				#3E7598;
$blue-light: 				#CDE4F2;
$turquoise-bright: 	#64C2C7;
$turquoise-dark: 		#59A3BE;
$turquoise-light:		#BBDADA;


// PRIMARY TEXT COLOR (IDEALLY DARK COLOR)
$color-primary: $blue-dark;
// CI COLOR
$color-secondary: $purple-light;
// SECOND CI COLOR
$color-tertiary: $blue-bright;


// LINK COLORS
$color-link: $color-tertiary;
$color-link-hover: $color-primary;
$color-error: #e00;

// BASE COLORS
$white: #FFF;
$black: #000;
$lightgrey: $purple-light;

 :root {
	--color-primary: #{$color-primary};
	--color-secondary: #{$color-secondary};
	--color-tertiary: #{$color-tertiary};
	--color-link: #{$color-link};
	--color-link-hover: #{$color-link-hover};
	--color-error: #{$color-error};
	--color-white: #{$white};
	--color-black: #{$black};
	--color-lightgrey: #{$lightgrey}; }

$colors: ('primary' $color-primary), ('secondary' $color-secondary), ('tertiary' $color-tertiary), ('link' $color-link), ('link-hover' $color-link-hover), ('error' $color-error), ('white' $white), ('black' $black), ('lightgrey' $lightgrey);


@each $color in $colors {
	$current-selector: nth($color, 1);
	$current-color: nth($color, 2);

	.#{$current-selector}_color {
		color: ($current-color);

		.contrast &, &.contrast {
			color: $black; } }

	.#{$current-selector}_bg {
		background: ($current-color);

		.contrast &, &.contrast {
			background: $white; } }

	.#{$current-selector}_border {
		border: 1px solid ($current-color);

		.contrast &, &.contrast {
			border-color: $black; } } }
input, textarea, button, select, label, a {
	-webkit-tap-highlight-color: rgba(0,0,0,0); }

a {
	color: inherit; }
