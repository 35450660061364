/* IMPORTANT: CHANGE THIS FILE ONLY IN FLOATWORK_ TEMPLATE */

/* FONTS */
$font-primary:																	'Lato', sans-serif;
$font-secondary:																$font-primary;
$font-tertiary:																	$font-primary;

/* PATHS */
$image_path:																		'../../images/';

/* BREAKPOINTS */
$min-body-width:																320px;
$smartphone_p:																	480px;
$smartphone:																		767px;
$tablet_p:																			990px;
$tablet_l:																			1199px;
$desktop:																				1366px;

/* FONT SIZES */
$html-font-size:																62.5%;

$base-font-family:															$font-primary;
$base-font-size:																16px;
$base-line-height:															24px;
$base-text-transform:														none;
$base-letter-spacing:														0.0em;
$base-font-weight:															300;

$h1-font-family:																$font-secondary;
$h1-font-size:																	36px;
$h1-line-height:																42px;
$h1-text-transform:															none;
$h1-letter-spacing:															0.0em;
$h1-font-weight:																300;

$h2-font-family:																$font-secondary;
$h2-font-size:																	20px;
$h2-line-height:																30px;
$h2-text-transform:															uppercase;
$h2-letter-spacing:															0.0em;
$h2-font-weight:																300;

$h3-font-family:																$font-secondary;
$h3-font-size:																	18px;
$h3-line-height:																28px;
$h3-text-transform:															uppercase;
$h3-letter-spacing:															0.0em;
$h3-font-weight:																300;

$h4-font-family:																$font-secondary;
$h4-font-size:																	24px;
$h4-line-height:																28px;
$h4-text-transform:															none;
$h4-letter-spacing:															0.0em;
$h4-font-weight:																300;

$h5-font-family:																$font-secondary;
$h5-font-size:																	22px;
$h5-line-height:																27px;
$h5-text-transform:															none;
$h5-letter-spacing:															0.0em;
$h5-font-weight:																300;

$h6-font-family:																$font-secondary;
$h6-font-size:																	18px;
$h6-line-height:																22px;
$h6-text-transform:															none;
$h6-letter-spacing:															0.0em;
$h6-font-weight:																300;

$header-menu-font-family:												$font-secondary;
$header-menu-font-size:													20px;
$header-menu-line-height:												24px;
$header-menu-text-transform:										none;
$header-menu-letter-spacing:										0em;
$header-menu-font-weight:												300;

$header-submenu-font-family:										$font-secondary;
$header-submenu-font-size:											17px;
$header-submenu-line-height:										24px;
$header-submenu-text-transform:									none;
$header-submenu-letter-spacing:									0em;
$header-submenu-font-weight:										300;

$header-menu-mobile-font-family:								$font-secondary;
$header-menu-mobile-font-size:									21px;
$header-menu-mobile-line-height:								26px;
$header-menu-mobile-text-transform:							none;
$header-menu-mobile-letter-spacing:							0em;
$header-menu-mobile-font-weight:								300;

$header-submenu-mobile-font-family:							$font-secondary;
$header-submenu-mobile-font-size:								18px;
$header-submenu-mobile-line-height:							21px;
$header-submenu-mobile-text-transform:					none;
$header-submenu-mobile-letter-spacing:					0em;
$header-submenu-mobile-font-weight:							300;

$footer-menu-font-family:												$font-secondary;
$footer-menu-font-size:													14px;
$footer-menu-line-height:												17px;
$footer-menu-text-transform:										none;
$footer-menu-letter-spacing:										0em;
$footer-menu-font-weight:												300;

$footer-submenu-font-family:										$font-secondary;
$footer-submenu-font-size:											12px;
$footer-submenu-line-height:										14px;
$footer-submenu-text-transform:									none;
$footer-submenu-letter-spacing:									0em;
$footer-submenu-font-weight:										300;

$list-font-family:															$font-primary;
$list-font-size:																$base-font-size;
$list-line-height:															$base-line-height;
$list-text-transform:														$base-text-transform;
$list-letter-spacing:														$base-letter-spacing;
$list-font-weight:															$base-font-weight;

$input-field-font-family:												$font-primary;
$input-field-font-size:													$base-font-size;
$input-field-line-height:												$base-line-height;
$input-field-text-transform:										$base-text-transform;
$input-field-letter-spacing:										$base-letter-spacing;
$input-field-font-weight:												$base-font-weight;

$button-font-family:														$font-primary;
$button-font-size:															$base-font-size;
$button-line-height:														$base-line-height;
$button-text-transform:													$base-text-transform;
$button-letter-spacing:													$base-letter-spacing;
$button-font-weight:														$base-font-weight;

$submit-button-font-family:											$font-primary;
$submit-button-font-size:												$base-font-size;
$submit-button-line-height:											$base-line-height;
$submit-button-text-transform:									$base-text-transform;
$submit-button-letter-spacing:									$base-letter-spacing;
$submit-button-font-weight:											$base-font-weight;

$select-font-family:														$font-primary;
$select-font-size:															$base-font-size;
$select-line-height:														$base-line-height;
$select-text-transform:													$base-text-transform;
$select-letter-spacing:													$base-letter-spacing;
$select-font-weight:														$base-font-weight;

$big-font-family:																$font-primary;
$big-font-size:																	$base-font-size*1.4;
$big-line-height:																$base-line-height*1.4;
$big-text-transform:														$base-text-transform;
$big-letter-spacing:														$base-letter-spacing;
$big-font-weight:																$base-font-weight;

$medium-font-family:														$font-primary;
$medium-font-size:															$base-font-size*1.2;
$medium-line-height:														$base-line-height*1.2;
$medium-text-transform:													$base-text-transform;
$medium-letter-spacing:													$base-letter-spacing;
$medium-font-weight:														$base-font-weight;

$small-font-family:															$font-primary;
$small-font-size:																$base-font-size*0.8;
$small-line-height:															$base-line-height*0.8;
$small-text-transform:													$base-text-transform;
$small-letter-spacing:													$base-letter-spacing;
$small-font-weight:															$base-font-weight;


/* GRID */
$grid-width:																		1150px;
$gutter-width:																	30px;
$outer-gutter-width:														0;

/* DESKTOP */
$header-height:																	50px;
$logo-width:																		20px;
$logo-height:																		40px;

/* MOBILE */
$mobile-logo-width:															20px;
$mobile-menu-button-outer-padding-ipad:					20px;
$mobile-menu-button-outer-padding-phone:				20px;
$mobile-bar-height:															50px;
$mobile-menu-button-width:											40px;
$mobile-menu-bar-height:												3px;
$mobile-menu-bar-gutter-vertical:								4px;

/* FORMS */
$input-height:																	35px;
$select-height:																	$input-height;
$search-height:																	60px;
$checkbox-size:																	35px;
$radio-btn-size:																20px;


/* SLIDESHOW */
$slider-arrow-color:														#333;
$slider-arrow-size:															20px;
$slider-arrow-position:													-25px;
$slider-dot-color:															#333;
$slider-dot-color-active:												$slider-dot-color;
$slider-dot-size:																10px;
$slider-prev-character:													'\f053';
$slider-next-character:													'\f054';
$slider-opacity-default:												0.75;
$slider-opacity-on-hover:												1;
$slider-opacity-not-active:											0.25;
